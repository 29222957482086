import { extendTheme } from "@chakra-ui/react"

const theme = {
  styles: {
    global: {
      "html, body": {
        scrollBehavior: "smooth",
      },
    },
  },
  colors: {
    primary: {
      100: "#FFB7A6",
      200: "#FF9A7C",
      300: "#FF8153",
      400: "#FF6B29",
      500: "#FF5800",
      600: "#D64100",
      700: "#AC2E00",
      800: "#831E00",
      900: "#591100",
    },
  },
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
}

export default extendTheme(theme)
